'use client';

import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import Error from 'src/views/Error';

type ErrorPageProps = {
    error: Error & { digest?: string };
    reset: () => void;
};

function ErrorPage({ error }: ErrorPageProps) {
    useEffect(() => {
        datadogRum.addError(error, {
            stack: error.stack,
            digest: error.digest,
        });
    }, [error]);

    return <Error type="unknown" isLoggedIn={false} />;
}

export default ErrorPage;
