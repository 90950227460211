'use client';

import HTMLReactParser from 'html-react-parser';
import { Button } from '@get-mosh/msh-shared';
import LayoutBasic from 'src/components/Layout/Basic';
import { useTrackPage } from 'src/analytics/Events';
import { PageType } from 'src/analytics/EventProps';
import Routes from 'src/constants/Routes';

export type ErrorProps = {
    type: string;
    title?: string;
    message?: string;
    isLoggedIn: boolean;
};

const Error = ({
    type,
    title = 'There was an error',
    message = 'Please try again later or contact us if you need further support.',
    isLoggedIn,
}: ErrorProps) => {
    useTrackPage('Error', {
        page_type: PageType.ERROR,
        title: title.toString(),
        error_type: type,
        error_message: message.toString(),
    });

    return (
        <LayoutBasic className="container flex max-w-3xl flex-grow flex-col justify-center gap-6 md:items-center">
            <div className="flex flex-col gap-3">
                <h4 className="text-green-faint-dark">Sorry!</h4>
                <h1>{title}</h1>
                <p className="text-grey-400 [&_a]:font-demi [&_a]:underline">
                    {typeof message === 'string'
                        ? HTMLReactParser(message)
                        : message}
                </p>
                <div className="mt-3 flex w-full flex-col gap-2 self-start md:w-fit md:flex-row md:items-start">
                    <Button
                        text={isLoggedIn ? 'Back to dashboard' : 'Back to home'}
                        as="a"
                        href={isLoggedIn ? Routes.NOTIFICATIONS : Routes.HOME}
                    />
                    <Button
                        text="Get help"
                        as="a"
                        href={isLoggedIn ? Routes.SUPPORT : Routes.SUPPORT_FAQ}
                        variant="tertiary"
                    />
                </div>
            </div>
        </LayoutBasic>
    );
};

export default Error;
