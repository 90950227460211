import { Subvertical, Vertical } from '../constants/Verticals';
import { Flow } from 'src/hooks/Payment/types';

export enum Brand {
    MOSH = 'Mosh',
    MOSHY = 'Moshy',
}

export enum PageType {
    APP_DOWNLOAD_PAGE = 'App download',
    BLOG = 'Blog',
    CATEGORY_PAGE = 'Category page',
    SEO_PAGE = 'SEO page',
    INFO_PAGE = 'Info page',
    PERFORMANCE_PAGE = 'Performance page',
    COMPETITOR_PAGE = 'Competitor Page',
    PLAN_PAGE = 'Plan page',
    DOCTOR_PAGE = 'Doctor page',
    CHECKOUT = 'Checkout',
    QUIZ = 'Quiz',
    ERROR = 'Error',
    PRIVACY_POLICY = 'Privacy policy',
    TERMS = 'Terms',
    THANK_YOU = 'Thank you',
}

export enum DestinationCategory {
    PREQUIZ = 'Pre-quiz',
    QUIZ = 'Quiz',
    USER_PORTAL = 'User portal',
    INFO_PAGE = 'Info page',
}

export enum ButtonAction {
    DIRECT = 'Direct',
    ACCORDION = 'Accordion',
    MODAL = 'Modal',
    SEND_MESSAGE = 'Send message',
    ADD_TO_CART = 'Add to cart',
}

export type EventProps = {
    user_id?: string;

    // Default event props
    page_type?: string; //i.e. blog_page, doctor_page etc - where the event is fired
    pageType?: string;
    destination_category?: DestinationCategory;
    destination_url?: string;
    vertical?: Vertical;
    location?: string; // where it is located within a page
    // TODO: Favour using sub_vertical instead of subvertical
    subvertical?: Subvertical.ED | Subvertical.PE;
    sub_vertical?: Subvertical.ED | Subvertical.PE;
    title?: string; //used to identify the main area of concern for example for accordion: faqs vs doctors vs my treatment etc
    brand?: Brand;
    button_action?: ButtonAction;
    platform?: string;

    // Navigation tracking
    referrer?: string;
    anonymousId?: string;
    path?: string;

    variation?: string; // slices variations
    // DEPRECATED: use path instead
    link?: string; //path where the button/link links
    // DEPRECATED: use path instead
    url?: string;

    // Plan
    plan?: string;
    planId?: string;
    plan_id?: string;

    // Growthbook
    experimentId?: string;
    variationId?: string;

    // Quiz
    quiz_token?: string;

    // Errors
    error_type?: string;
    error_message?: string;

    // Misc
    email?: string;
    version?: string;
    medication?: string;
    tab?: string; // tab label
    label?: string; // buttons / cards labels - CTAs
    item?: number;
    bmi?: string | number; // used for weight loss BMI calculator events

    addon_ids?: Array<string>;
    value?: string;
    payment_method?: string;
    coupon_code?: string;
    discount_amount?: string;
    referral?: boolean;
    flow?: Flow;
};
