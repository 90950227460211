import React from 'react';
import Page from '../Page/Page';
import BasicHeader, { BasicHeaderProps } from '../Theme/Header/BasicHeader';

type LayoutBasicProps = {
    className?: string;
    children: React.ReactNode;
} & BasicHeaderProps;

const LayoutBasic = ({
    className,
    children,
    ...headerProps
}: LayoutBasicProps & BasicHeaderProps) => {
    return (
        <React.Fragment>
            <BasicHeader {...headerProps} />
            <Page className={className}>{children}</Page>
        </React.Fragment>
    );
};

export default LayoutBasic;
